import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Router, RouterStateSnapshot
} from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  constructor(private userService: UserService,  private router: Router) {}
  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.userService.isLoggedIn$.getValue()) {
      this.userService.login();
      return false;
    }
    if (!this.userService.idValidated$.getValue()) {
      this.router.navigateByUrl(`/cpr-validation?redirect_uri=${state.url}`)
      return false;
    }
    return true;
  }
}
