import { Injectable } from '@angular/core';
import { SiteSettingsService as AbstractSiteSettingsService } from '@sitemule/ng-components/abstract-services/site-settings.service';
import { BehaviorSubject } from 'rxjs';
import { IAddress } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService extends AbstractSiteSettingsService {

  override currency$ = new BehaviorSubject('DKK');
  override locale$ = new BehaviorSubject('da');
  availableLanguages$ = new BehaviorSubject(['da']);

  override siteName$ = new BehaviorSubject('Politiets Sprogforbund');

  primaryAddress$ = new BehaviorSubject({
    name: 'Politiets Sprogforbund',
    address1: 'Codanhus, Gl. Kongevej 60',
    zipCode: '1850',
    city: 'Frederiksberg',
    country: 'Danmark',
    phone: '4515 2088',
    openingHours: 'Mandag til Torsdag kl. 10:00 - 13:00',
    email: 'mail@politiets-sprogforbund.dk',
    bank: {
      name: 'Lan & Spar',
      reg: '0400',
      account: '4023883290'
    }
  } as IAddress);

  constructor() {
    super();
  }
}
