import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { IGNORE_AUTHORIZED_CHECK, IGNORE_TOAST_ON_STATUS } from '../interceptors/http-interceptor.interceptor';
import { IUserSession } from '../interfaces';
import { IMemberResponse } from './member.service';

const mitIDUrl = `${location.protocol}//${location.hostname}/utils/public/auth/mitid`;

// Uncomment this to work on localhost
// const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjA0ODA1OEJCNTlGNEQzMDA3MDQ1ODk2RkQ0ODhDRTgxRjRFQjQ5MjMiLCJ0eXAiOiJhdCtqd3QifQ.eyJpc3MiOiJodHRwczovL3BwLm5ldHNlaWRicm9rZXIuZGsvb3AiLCJuYmYiOjE2Njk4MTg2MTksImlhdCI6MTY2OTgxODYxOSwiZXhwIjoxNjY5ODIyMjE5LCJzY29wZSI6WyJvcGVuaWQiLCJtaXRpZCJdLCJhbXIiOlsiY29kZV9hcHAiXSwiY2xpZW50X2lkIjoiMGE3NzVhODctODc4Yy00YjgzLWFiZTMtZWUyOWM3MjBjM2U3Iiwic3ViIjoiYmM3ZmUzZjQtYTk1ZS00NzIyLWI0OTQtMjllYWExMzRlYjEwIiwiYXV0aF90aW1lIjoxNjY5ODE4NjE4LCJpZHAiOiJtaXRpZCIsImFjciI6Imh0dHBzOi8vZGF0YS5nb3YuZGsvY29uY2VwdC9jb3JlL25zaXMvU3Vic3RhbnRpYWwiLCJuZWJfc2lkIjoiMWJlODNiM2MtODAyMy00MDMwLWJmNjQtYmEzODgxZmEwMjBlIiwiaWRwX3RyYW5zYWN0aW9uX2lkIjoiZGYxYzQyMGYtZDRjZS00OWM4LTk4ZTYtYzBmOTNiMThlZDhkIiwidHJhbnNhY3Rpb25faWQiOiI5Njg4NTA5Ny0zZDRkLTQ5YmYtYWEyYy01MTY2ZGU5OWM4NWUiLCJqdGkiOiIyRDZGQkIyNzhGODlDODdEQzBEN0NFMzlFOUYwOTcyMiJ9.hYLiDJOOAUbQF6qSmYpwNu0MJs0JElCHA5IKC8p47bQVnwhvmlBrlUJvshEkPYBhbuGwe8jEqxTWBvHDGqb4yyONcif4n3AuNvFTmYQBOFHJhPQM8JK5tEQVq0M1ao1LS8Ye5L4wnrZHhHTIU_6jWbeyHhE9uXlzGcg3-gaWIwcj0t5WATXbJFCsKUKv6sC5eoYkGq55_ktvHAxY_-qZ9o4AkV98ZrUNzFBTJwlgjJHal0ux0ZD39QIN5B2UkBca0DBKw0aEVmczyH4K1yRNTyXjBIhimpUygrsW1Qvr8hhqUnlOMPXYGOrO8ka_n8WvPb4ALFLjseGyi8MNIP9xFQ'
// document.cookie = `tkn=${token};Path=/;`;
// const mitIDUrl = `https://minpsfdev.proxy.sitemule.com/utils/public/auth/mitid`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
constructor(private http: HttpClient) {}

  private _loggedInUser$ = new BehaviorSubject(undefined as IUserSession | undefined);

  memberId$ = this._loggedInUser$.pipe(map((user) => user?.id));
  loggedIdMember$ = this._loggedInUser$.asObservable();

  isLoggedIn$ = new BehaviorSubject(true);
  idValidated$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject('');

  load(): Promise<any> {
    return new Promise<void>((resolve) => {
      // Call any service, if it returns error we are not logged in
      // If 406, we are logged in but need CPR validation
      // If 401, we are clearly not logged in
      this.http.get<IMemberResponse>('/api/members', {
        context: new HttpContext().set(IGNORE_AUTHORIZED_CHECK, true).set(IGNORE_TOAST_ON_STATUS, [404])
      }).subscribe({
        next: (res) => {
          if (!this.isLoggedIn$.getValue()) {
            this.isLoggedIn$.next(true);
          }
          if (!this.idValidated$.getValue()) {
            this.idValidated$.next(true);
          }
          this._loggedInUser$.next({
            id: `${res.memberId}`,
            name: res.name,
            balance: res.balance || 0,
            accountId: res.accountId ? `${res.accountId}` : '',
            mobile: res.mobilePrivate,
            workEmail: res.mailWork,
            privateEmail: res.mailPrivate,
            dateOfBirth: res.birthday,
            address1: res.address1,
            address2: res.address2,
            zipCity: res.zipCity,
            jobTitle: res.jobTitle,
          });
          resolve();
        },
        error: (e: HttpErrorResponse) => {
          if (e.status === 401) {
            if (this.isLoggedIn$.getValue()) {
              this.isLoggedIn$.next(false);
            }
            if (this.idValidated$.getValue()) {
              this.idValidated$.next(false);
            }
          }
          else if (e.status === 406) {
            if (!this.isLoggedIn$.getValue()) {
              this.isLoggedIn$.next(true);
            }
            if (this.idValidated$.getValue()) {
              this.idValidated$.next(false);
            }
          }
          else if (e.status === 404) {
            if (!this.isLoggedIn$.getValue()) {
              this.isLoggedIn$.next(true);
            }
            if (!this.idValidated$.getValue()) {
              this.idValidated$.next(true);
            }
            this.error$.next(e.error.errorCode);
          }
          else {
            throw new Error(`Unknown status code: ${e.status}`);
          }

          resolve();
        },
      });
    });
  }

  login(redirect_uri = location.href) {
    location.replace(
      `${mitIDUrl}/login${redirect_uri ? `?redirect_uri=${redirect_uri}` : ''}`
    );
  }
  logout() {
    return this.http.get<void>(`${mitIDUrl}/logout`).pipe(
      tap(() => {
        this.idValidated$.next(false);
        this.isLoggedIn$.next(false);
      })
    );
  }
  validateCpr(cpr: string) {
    return this.http.post<void>(`/api/validateCPR`, { cpr }).pipe(
      tap(() => {
        if (!this.idValidated$.getValue()) {
          this.idValidated$.next(true);
        }
      }),
    )
  };
}
