import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/loggedin.guard';
import { UserErrorGuard } from './guards/user-error.guard';

const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'association/:associationId/notes',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/association-notes/association-notes.module').then(
        (mod) => mod.AssociationNotesModule
      ),
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./views/public-user-notification-settings/user-notification-settings.module').then(
        (mod) => mod.UserNotificationSettingsViewModule
      ),
  },
  {
    path: 'cpr-validation',
    loadChildren: () =>
      import('./views/cpr-validation/cpr-validation.module').then(
        (mod) => mod.CPRValidationModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/dashboard/dashboard.module').then(
        (mod) => mod.DashboardModule
      ),
  },
  {
    path: 'member',
    redirectTo: 'member/transactions',
    pathMatch: 'full',
  },
  {
    path: 'member/transactions',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/transactions/transactions.module').then(
        (mod) => mod.TransactionsModule
      ),
    data: {
      type: 'member',
    },
  },
  {
    path: 'association/:associationId',
    redirectTo: 'association/:associationId/transactions',
    pathMatch: 'full',
  },
  {
    path: 'association/:associationId/transactions',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/transactions/transactions.module').then(
        (mod) => mod.TransactionsModule
      ),
    data: {
      type: 'association',
    },
  },
  {
    path: 'association/:associationId/annualreport',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/annual-report/annual-report.module').then(
        (mod) => mod.AnnualReportModule
      ),
    data: {
      type: 'association',
    },
  },
  {
    path: 'member/annualreport',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/annual-report/annual-report.module').then(
        (mod) => mod.AnnualReportModule
      ),
    data: {
      type: 'member',
    },
  },
  {
    path: 'association/:associationId/history',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/history/history.module').then(
        (mod) => mod.HistoryModule
      ),
      data: {
        type: 'association',
      },
  },
  {
    path: 'association/:associationId/members',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/members/members.module').then(
        (mod) => mod.MembersModule
      ),
      data: {
        type: 'association',
      },
  },
  {
    path: 'association/:associationId/settings',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/settings/settings.module').then(
        (mod) => mod.SettingsModule
      ),
      data: {
        type: 'association',
      },
  },
  {
    path: 'user',
    redirectTo: 'user/profile',
    pathMatch: 'full',
  },
  {
    path: 'user/profile',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/user-profile/user-profile.module').then(
        (mod) => mod.UserProfileModule
      ),
    data: {
      type: 'association',
    },
  },
  {
    path: 'user/notification',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/user-notification-settings/user-notification-settings.module').then(
        (mod) => mod.UserNotificationSettingsViewModule
      ),
  },
  {
    path: 'user/logout',
    loadChildren: () =>
      import('./views/logout/logout.module').then(
        (mod) => mod.LogoutModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./views/login-aborted/login-aborted.module').then(
        (mod) => mod.LoginAbortedModule
      ),
  },
  {
    path: 'user-error/:errorCode',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./views/user-error/user-error.module').then(
        (mod) => mod.UserErrorModule
      ),
  },
  {
    path: '**',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/404/404.module').then((mod) => mod.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
