import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { SharedErrorHandler } from '@sitemule/ng-components';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends SharedErrorHandler {
  override handleError(error: any) {
    super.handleError(error);

    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    }
  }
}
