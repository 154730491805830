import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Router
} from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserErrorGuard {
  constructor(private userService: UserService,  private router: Router) {}
  canActivate(_next: ActivatedRouteSnapshot) {
    if (this.userService.error$.getValue()) {
      this.router.navigateByUrl(`/user-error/${this.userService.error$.getValue()}`);
      return false;
    }
    return true;
  }
}
