
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: "https://85ac51aa7c2748418ebee5e729ab4e87@o263138.ingest.sentry.io/4504118462513152",
  });
  enableProdMode();
}

// This console is for Sentry, so we know where user landed.
console.log(`Landed at ${location.toString()}`)

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
