import { CurrencyPipe, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import da from '@angular/common/locales/da';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/ng-components/abstract-services/site-settings.service';
import { ToastModule } from '@sitemule/ng-components/components/toast';
import { AddAcceptLanguageHttpInterceptor } from '@sitemule/ng-components/http-interceptors/add-accept-language';
import { HttpErrorInterceptor as BaseHttpErrorInterceptor } from '@sitemule/ng-components/http-interceptors/log-error';
import { RetryHttpInterceptor } from '@sitemule/ng-components/http-interceptors/retry-http';
import { CustomCurrencyPipe } from '@sitemule/ng-components/pipes/custom-currency';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppErrorHandler } from './error-handlers/app.error-handler';
import { HttpErrorInterceptor } from './interceptors/http-interceptor.interceptor';
import { SiteSettingsService } from './services/siteSettings.service';

registerLocaleData(da);

const translateLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, '/assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => () =>
        appService.downloadInitialData(),
      deps: [AppService],
      multi: true,
    },
    {
      provide: SITE_SETTINGS_SERVICE_TOKEN,
      useClass: SiteSettingsService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAcceptLanguageHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseHttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    CurrencyPipe,
    CustomCurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
