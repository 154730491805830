import {
  HttpContextToken, HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ToastService } from '@sitemule/ng-components/components/toast';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';

const PERMANENT_TOAST_IGNORE_STATUS = [401, 406]; // ['Unauthorized', 'Missing CPR']
export const IGNORE_TOAST_ON_STATUS = new HttpContextToken<number[]>(() => []);
export const IGNORE_AUTHORIZED_CHECK = new HttpContextToken<boolean>(() => false);
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        catchError((error) => {
          const ignoreAuthorizedCheck = request.context.get(IGNORE_AUTHORIZED_CHECK);
          if (error.status === 401 && !ignoreAuthorizedCheck) {
            const userService = this.injector.get(UserService);

            if (userService) {
              userService.login();
            }
          }
          else {
            const toast = this.injector.get(ToastService);
            const ignoreToast = [...PERMANENT_TOAST_IGNORE_STATUS, ...request.context.get(IGNORE_TOAST_ON_STATUS)].indexOf(error.status) > -1;
            if (!ignoreToast && toast) {
              toast.push({
                type: 'warning',
                title: error.statusText,
                message: error.error?.message || error.message,
                autoHide: true
              });
            }
          }

          return throwError(() => error);
        })
      );
  }
}
